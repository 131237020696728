import validate from "/overbookd/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.1__ioredis@5._srgzndvcarbb2iyuxbebjookdy/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/overbookd/apps/web/middleware/01.auth.global.ts";
import _02_45user_45global from "/overbookd/apps/web/middleware/02.user.global.ts";
import _03_45membership_45application_45global from "/overbookd/apps/web/middleware/03.membership-application.global.ts";
import _04_45routing_45global from "/overbookd/apps/web/middleware/04.routing.global.ts";
import _05_45store_45data_45global from "/overbookd/apps/web/middleware/05.store-data.global.ts";
import manifest_45route_45rule from "/overbookd/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.1__ioredis@5._srgzndvcarbb2iyuxbebjookdy/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _02_45user_45global,
  _03_45membership_45application_45global,
  _04_45routing_45global,
  _05_45store_45data_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}