import payload_plugin_1VmXHbb4Uo from "/overbookd/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.6.3_vue@3.5.13_typescript@5.6.3___rollup@4.28.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_8qqpLTqCd9 from "/overbookd/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.1__ioredis@5._srgzndvcarbb2iyuxbebjookdy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_f5mko0fQln from "/overbookd/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.1__ioredis@5._srgzndvcarbb2iyuxbebjookdy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_oOsK8woC84 from "/overbookd/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.1__ioredis@5._srgzndvcarbb2iyuxbebjookdy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_F3v5DlFBAH from "/overbookd/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.1__ioredis@5._srgzndvcarbb2iyuxbebjookdy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_nvEtnQ9IAR from "/overbookd/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.1__ioredis@5._srgzndvcarbb2iyuxbebjookdy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_unf5lv0Sf7 from "/overbookd/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.1__ioredis@5._srgzndvcarbb2iyuxbebjookdy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_CgSnX5FTUj from "/overbookd/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.1__ioredis@5._srgzndvcarbb2iyuxbebjookdy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_hfPj80CeMO from "/overbookd/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.6.3_vue@3.5.13_typescript@5.6.3___rollup@4.28.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/overbookd/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_YFb27gSN6n from "/overbookd/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.15.0_jiti@2.4.1__ioredis@5._srgzndvcarbb2iyuxbebjookdy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chartjs_client_N7GULTnRpX from "/overbookd/apps/web/plugins/chartjs.client.ts";
import vue_html_secure_client_vi14lb5zJW from "/overbookd/apps/web/plugins/vue-html-secure.client.ts";
import vuetify_client_69rYAaPZnF from "/overbookd/apps/web/plugins/vuetify.client.ts";
export default [
  payload_plugin_1VmXHbb4Uo,
  revive_payload_client_8qqpLTqCd9,
  unhead_f5mko0fQln,
  router_oOsK8woC84,
  payload_client_F3v5DlFBAH,
  navigation_repaint_client_nvEtnQ9IAR,
  check_outdated_build_client_unf5lv0Sf7,
  chunk_reload_client_CgSnX5FTUj,
  plugin_vue3_hfPj80CeMO,
  components_plugin_KR1HBZs4kY,
  prefetch_client_YFb27gSN6n,
  chartjs_client_N7GULTnRpX,
  vue_html_secure_client_vi14lb5zJW,
  vuetify_client_69rYAaPZnF
]