// src/index.ts
var BENEVOLE_CODE = "benevole";
var HARD_CODE = "hard";
var SOFT_CODE = "soft";
var VIEUX_CODE = "vieux";
var ORGA_CODE = "orga";
var BUREAU_CODE = "bureau";
var LOG_ELEC_CODE = "elec";
export {
  BENEVOLE_CODE,
  BUREAU_CODE,
  HARD_CODE,
  LOG_ELEC_CODE,
  ORGA_CODE,
  SOFT_CODE,
  VIEUX_CODE
};
