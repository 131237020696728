var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/user.utils.ts
function buildUserName(user) {
  return `${user.firstname} ${user.lastname}`;
}
__name(buildUserName, "buildUserName");
function nicknameOrName(user) {
  const { nickname, ...name } = user;
  return nickname || buildUserName(name);
}
__name(nicknameOrName, "nicknameOrName");
function nicknameOrFirstName({ nickname, firstname }) {
  return nickname || firstname;
}
__name(nicknameOrFirstName, "nicknameOrFirstName");
function buildUserNameWithNickname(user) {
  const nickname = user.nickname ? ` (${user.nickname}) ` : " ";
  return `${user.firstname}${nickname}${user.lastname}`;
}
__name(buildUserNameWithNickname, "buildUserNameWithNickname");
function toStandAloneUser(user) {
  const name = buildUserNameWithNickname(user);
  return { id: user.id, name };
}
__name(toStandAloneUser, "toStandAloneUser");
export {
  buildUserName,
  buildUserNameWithNickname,
  nicknameOrFirstName,
  nicknameOrName,
  toStandAloneUser
};
